import GSheetReader from 'g-sheets-api';
import _ from 'lodash';
import { useEffect, useState, useRef } from 'react';
import { ulid } from 'ulid';
import constants from './constants';
import secrets from './secrets';
import "./app.css";
import BottomLogo from './assets/01072_vku22_idagifremtiden_bund-grafik.png';
import TopLogo from './assets/01072_vku22_idagifremtiden_top-grafik.png';
import BottomLogo2 from './assets/01072_vku22_logo.png';

function hasOwnProperty(obj, field) {
  return Object.prototype.hasOwnProperty.call(obj, field);
}

function App() {
  const [todays, setTodays] = useState(JSON.parse(localStorage.getItem(constants.localStorageToday)) ?? []);
  const [futures, setFutures] = useState(JSON.parse(localStorage.getItem(constants.localStorageFuture)) ?? []);
  const [displayTodays, setDisplayTodays] = useState([]);
  const [displayFutures, setDisplayFutures] = useState([]);
  const refreshTimeoutRef = useRef(null);
  const mixTimeoutRef = useRef(null);
  const [hideTexts, setHideTexts] = useState(false);

  const refreshData = () => {
    const options = {
      apiKey: secrets.apiKey,
      sheetId: secrets.sheetId,
      sheetNumber: 1,
      sheetName: secrets.sheetName,
      returnAllResults: true
    };

    GSheetReader(
      options,
      results => {
        const newTodays = [];
        const newFutures = [];

        results.forEach((result) => {
          if (hasOwnProperty(result, 'I dag')) {
            const entry = result['I dag'];

            if (entry.indexOf('I dag ') === 0) {
              const text = entry.substring("I dag".length);
              newTodays.push({text, author: result['Forfatter - I dag'], key: ulid()});
            }
          }

          if (hasOwnProperty(result, 'I fremtiden')) {
            const entry = result['I fremtiden'];

            if (entry.indexOf('I fremtiden ') === 0) {
              const text = entry.substring("I fremtiden".length);
              newFutures.push({text, author: result['Forfatter - I fremtiden'], key: ulid()});
            }
          }
        });

        if (newTodays.length > 0) {
          setTodays(newTodays);
          localStorage.setItem(constants.localStorageToday, JSON.stringify(newTodays));
        }

        if (newFutures.length > 0) {
          setFutures(newFutures);
          localStorage.setItem(constants.localStorageFuture, JSON.stringify(newFutures));
        }

        if (displayTodays.length === 0 && displayFutures.length === 0) {
          mixEntries();
        }
      }
    ).catch(err => {
      console.log(err);
    });
  }

  const mixEntries = () => {
    setDisplayTodays(_.sampleSize(todays, 10));
    setDisplayFutures(_.sampleSize(futures, 10));
  }

  useEffect(() => {
    refreshData();
    // Refresh data every 15 minutes.
    refreshTimeoutRef.current = setInterval(refreshData, 1000 * 60 * 15);

    // Mix texts every 3 minutes.
    mixTimeoutRef.current = setInterval(mixEntries, 1000 * 60 * 3);

    const query = new URLSearchParams(document.location.search);
    setHideTexts(query.get('hideText') === 'true');

    return () => {
      if (refreshTimeoutRef?.current) {
        clearInterval(refreshTimeoutRef.current);
      }
      if (mixTimeoutRef?.current) {
        clearInterval(mixTimeoutRef.current);
      }
    }
  }, []);

  const appStyle = {
    fontFamily: "\"Trebuchet MS\", Helvetica, sans-serif",
    lineHeight: "1.5",
    fontSize: "12px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  };

  const colStyle = {
    margin: "1em",
    flexBasis: "0",
    flexGrow: "1",
    maxWidth: "440px",
    lineHeight: "1.5"
  };

  const colHeadingStyle = {
    textAlign: "center",
    marginBottom: ".5em",
  }

  const contentStyle = {
    display: "flex",
    textAlign: "center",
    padding: "1em",
  }

  return (
    <>
      <div className="App" style={appStyle}>
        {!hideTexts && <>
          <div style={{margin: "20px"}}><img src={TopLogo} alt="Logo" /></div>
          <div style={{maxWidth: "800px"}}>
            Hvem er Vestegnen anno 2022, og hvor er vi på vej hen? Hvilken fremtid drømmer vi om?<br/>
            Dette fælles digt er skrevet af børn, unge og voksne i Albertslund, Hvidovre, Høje-Taastrup, Ishøj og Vallensbæk<br/>
            - sammen med en robot.<br/>
            Du kan generere dit eget helt nye digt ved at trykke på knappen.
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
          }}><a type="button" onClick={mixEntries} className="renew-button">Nye stemmer</a></div>
          <hr style={{ width: "90%", color: '#ccc', backgroundColor: '#ccc', height: .1, borderColor : '#fff'}} />
        </>}
        <div style={contentStyle}>
          <div style={colStyle}>
            <div style={colHeadingStyle}><strong>I dag</strong></div>
            {displayTodays.map((element) => (
              <div key={element.key} title={element.author}><span>I dag </span>{element.text}</div>
            ))}
          </div>

          <div style={colStyle}>
            <div style={colHeadingStyle}><strong>I fremtiden</strong></div>
            {displayFutures.map((element) => (
              <div key={element.key} title={element.author}><span>I fremtiden </span>{element.text}</div>
            ))}
          </div>
        </div>
        {!hideTexts && <>
          <hr style={{ width: "90%", color: '#ccc', backgroundColor: '#ccc', height: .1, borderColor : '#fff', marginBottom: "20px"}} />
          <div style={{maxWidth: "800px", fontSize: "10px"}}>
            Bliv selv en del af digtet! Send en linje om "I dag…" og "I fremtiden…" til <a href="mailto:idagifremtiden@gmail.com">idagifremtiden@gmail.com</a><br/>
            Skriv hvor du kommer fra og evt. navn, og bliv en del af et hav af stemmer på Vestegnen.<br/>
            <br/>
            Digterrobotten blander udsagnene vilkårligt hver 3. minut.<br/>
            Hold musen over udsagnet for at se, hvor det kommer fra.<br/>
            <br/>
            <div><img src={BottomLogo} alt="Logo" /></div>
            <br/>
            Fællesdigtet er igangsat til Vestegnens Kulturuge 2022 af bibliotekerne i Albertslund, Hvidovre, Høje-Taastrup, Ishøj og Vallensbæk<br/>
            og kan opleves i live-fortolkninger 9.-18. september 2022 i de fem kommuner, se mere på <a href="https://www.vkir.dk">www.vkir.dk</a><br/>
          </div>
          <div style={{display: "flex", flexDirection: "row-reverse", justifyContent: "space-evenly", width: "100%", alignItems: "end", margin: "20px"}}>
            <div style={{fontSize: "8px", width: "33.3%"}}>
              Grafisk design: Mette Secher · Programmering: Troels Ugilt Jensen
            </div>
            <div style={{width: "33.3%"}}><img src={BottomLogo2} alt="Logo" /></div>
            <div style={{width: "33.3%"}}></div>
          </div>
        </>}
      </div>
    </>
  );
}

export default App;
